.about-wrapper{
    width: 100%;
    // min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 0% 5%;
    padding-top: 15%;
    margin-bottom: 10%;

    #italic{
        font-family: "Baskerville", serif;
        font-style: italic;
        color: var(--primary-color-gold);
    }
    #gold{
        font-weight: 200;
        color: var(--primary-color-gold);
    }
}
  



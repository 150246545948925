* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'poppins', sans-serif;
  font-weight: 300;
}

html {
  font-size: 62.5%;
  --header1: calc(4rem + 1vw);
  --header2: calc(2.1rem + 1vw);
  --header3: calc(1rem + 1vw);
  --header4: calc(1.5rem + 0.5vw);
  --text: calc(0.5rem + 0.7vw);
  --big: calc(40px + 5vw);
  --primary-color: #fbfbfb;
  --primary-color-gold: #d8ac64;
  --primary-color-dark: #282729;
  --primary-color-dark-rgb: rgba(62, 60, 63, 0.8);
  --primary-color-gold-rgb: rgba(216, 172, 100, 0.8);
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.container {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

// Font
h1 {
  font-size: var(--header1);
  letter-spacing: -5px;
}
h2 {
  font-size: var(--header2);
}
h3 {
  font-size: var(--header3);
}
h4 {
  font-size: var(--header4);
}
li,
button,
label,
input,
textarea,
a,
p {
  color: var(--primary-color-dark);
  font-size: var(--text);
  font-weight: 300;
}
a {
  text-decoration: none;
}

body {
  background-color: var(--primary-color);
  color: var(--primary-color-dark);
  overflow-x: hidden;
  // overflow-y: hidden;
}

#gold{
  font-weight: 200;
  color: var(--primary-color-gold);
}





.portofolio-info-wrapper{
    width: 100%;
    padding: 0% 5%;
    padding-top: calc(30px + 5vw);
    overflow: hidden;
    display: flex;
    flex-direction: column;

    //Banner
    .pi-banner{
        padding-top: 3%;

        .image{
            position: relative;
            width: 100%;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                object-fit:cover;
            }
            .mask{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: var(--primary-color);
            }
        }
        .image-info{
            width: 100%;
            height: fit-content;
            display: flex;
            margin-top: 3%;
            margin-bottom: 10%;

            .image-info-name{
                height: 100%;
                width: 60%;
                display: flex;
                flex-direction: column;

                
                .info-name-title{
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    
                    h1{
                        font-weight: 300;
                        font-size: calc(5px + 6vw);
                        letter-spacing: -0.2vw;
                    }
                    h3{
                        font-weight: 300;
                        opacity: 0.5;
                    }
    
                }
        
                .details{
                    display: flex;
                    flex-direction: row;
                    margin-top: 5%;

                    h4{
                        font-weight: 300;
                    }
                    p{
                        font-weight: 300;
                        opacity: 0.5;
                    }
                }
            }
            .image-info-description{
                height: fit-content;
                p{
                    margin-bottom: 5px;
                }
                svg{
                    height: 5px;
                    line{
                        stroke: rgb(216, 172, 100, 0.8);
                        stroke-width: 1;
                    }
                }

            }
        }
    }

    //Gallery
    .pi-gallery{
        width: 100%;
        margin-bottom: 100px;
        
        .grid-list{
            display: grid;
            .grid-image-container{
                width: 100%;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
  
        .gallery-wrapper-bottom{
            display: flex;
            flex-direction: row;
            justify-content: end;
            gap: 10px;
            padding-top: 1.5%;
      
            .back-button{
                display: flex;
                margin-top: 10%;
            }
        }
    }
}


//Desktop
@media (min-width: 1025px) {
    .image{
        height: calc(20vh + 35vw);
    }
    .details{
        gap: 70px;
    }
 
}

//Small Desktop, Tab
@media (min-width: 768px) and (max-width: 1024px) {
    .image{
        height: calc(45vh + 20vw);
    }
    .details{
        gap: 60px;
    }

}

@media (max-width: 768px) {
    .gallery-wrapper-bottom{
        .left{
            p{
                width: 70%;
            }
        }
        .back-button{
            width: 70%;
            display: flex;
            margin-top: 5%;
            justify-content: end;
        }
    }   

}

//Above Mobile
@media (min-width: 576px) {
    .image-info{
        flex-direction: row;
        align-items: end;
        .image-info-name{
            gap: 40px;

            h3{
                margin-top: -25px;
            }
        }
        .image-info-description{
            width: 40%;
        }
    }
    .grid-list{
        gap: 20px;
        grid-template-columns: repeat(auto-fit, minmax(calc(40%), 1fr));

        .grid-image-container{
            height: 40vw;
        }
    }
}

//Mobile
@media (max-width: 576px) {
    .image{
        height: calc(50vh + 35vw);
    }
    .image-info{
        flex-direction: column;

        .image-info-name{
            gap: 5px;
            h3{
                margin-top: -10px;
            }

            .details{
                gap: 20px;
            }
        }
    }

    .grid-list{
        gap: 10px;
        grid-template-columns: repeat(auto-fit, minmax(calc(100%), 1fr));
        .grid-image-container{
            height: 80vw;
        }
    }

}

.aw-introduction{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 5vw;

    .i-description{
        width: 40vw;
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding-top: 30px;
        .title{
            h1{
                font-size: 7vw;
                font-weight: 200;
            }
            h2{
                font-size: 60px;
                margin-top: -30px;
            }
        }
        p{
            font-size:1.5vw;
            font-weight: 200;
        }
    }

    .i-banner{
        width: 100%;
        height: 40vw;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

//Tab
@media (max-width: 768px) {
    .aw-introduction{
        flex-direction: column;

        .i-description{
            width: 100%;
            .title{
                h1{
                    font-size: 12vw;
                    font-weight: 200;
                }
                h2{
                    font-size: 65px;
                    margin-top: -30px;
                }
            }
            p{
                font-size:17px;
                font-weight: 200;
            }
        }

        .i-banner{
            width: 100%;
            height: 400px;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}


//Mobile
@media (max-width: 576px) {
    .aw-introduction{
        flex-direction: column;
        .i-description{
            .title{
                h1{
                    font-size: 20vw;
                    font-weight: 200;
                }
                h2{
                    font-size: 65px;
                    margin-top: -30px;
                }
            }
        }
        .i-banner{
            width: 100%;
            height: 400px;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
.team-container{
    width: 100%;
    height: fit-content;
    margin-bottom: 120px ;
    display: flex;
    flex-direction: row;
    gap: 5vw;

    .team-description{
        width: 70%;
        display: flex;
        flex-direction: column;
        gap: 30px;

        h2{
            font-size: 6vw;
            font-weight: 300;
            line-height: 5vw;
        }
        p{
            width: 80%;
            font-size: 20px;
            font-weight: 200;
        }
    }

    .team-image{
        width: 100%;
        .team-image-grid{
            display: grid;
            gap: 25px;
            .team-image-details{
                display: flex;
                flex-direction: column;
                gap: 15px;
                img{
                    width: 100%;
                    height: 100%;
                }
                .team-image-details-name{
                    h3{
                        font-size: 30px;
                        font-weight: 300;
                    }
                    p{
                        font-size: 15px;
                        opacity: 0.7;
                        margin-top: -5px;
                    }
                }
            }
        }
    }
}

//Desktop
@media (min-width: 1440px) {
    .team-image-grid{
        display: grid;
        gap: 25px;
        grid-template-columns: repeat(auto-fit, minmax(calc(23vw), 1fr));
    }
}


//Mobile
@media (max-width: 576px) {
    .team-container{
        flex-direction: column;
        margin-bottom: 50px ;
        .team-description{
            width: 100%;
            gap: 2vw;
            h2{
                font-size: 13vw;
                line-height: 13vw;
            }
            p{
                width: 100%;
                font-size: 15px;
            }
        }
    }
}

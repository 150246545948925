.slider {
  position: relative;
  overflow: hidden;
  scroll-behavior: smooth;
  
  .slider-wrapper {
    width: max-content;
    display: flex;
    gap: 1vw;  

    .slide {
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform .3s;
        filter: brightness(0.7);
      }
      .slide-title{
        position: absolute;
        bottom: 0;
        color: white;
        z-index: 10;
        margin: 15px 20px;
        transition: ease-in 0.3s;
        transition-delay: 0.3s;
        h2{
          opacity: 0.8;
          font-weight: 200;
        }
      }
      .img-info{
        position: absolute;
        top: 0;
        display: flex;
        align-items: end;
        background-color: #000;
        opacity: 0;
        transition: ease-in 0.4s;
        padding: 20px 30px;
        .info-container{
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: end;

          .info-left{
            h4{
              opacity: 0.9;
              font-weight: 200;
              color: var(--primary-color);
            }
          }
          .info-right{ 
   
            color: var(--primary-color);
          }
        }
      }
    }

    // Animation
    .slide:hover{
      img{
        transform: scale(1.03);
        filter: blur(10px) brightness(0.7);
      }
      .slide-title{
        opacity: 0;
        transition: none;
        transition-delay: 0s;
      }
      .img-info{
        opacity: 0.6;

        .info-right{
          .info-arrow{
            animation: arrow_up 1s forwards;
          }
        }
      }
    }
  }
}
@keyframes arrow_up{
  0%{
    transform: translate(0, 0);
  }
  100%{
    transform: translate(5px, -5px);
  }
}

//Desktop
@media (min-width: 1280px) {
  .slide {
    width: calc(65vh - 2vw);
    height: calc(65vh - 2vw);
  }
  .img-info{
    width: calc(65vh - 2vw);
    height: calc(65vh - 2vw);

    .info-container{
      .info-left{
        h2{
          margin-bottom: 10px;
        }
      }
    }
  }
}

//Tablet
@media (min-width: 768px ) and (max-width: 1279px) {
  .slide {
    width: calc(55vh - 5vw);
    height: calc(55vh - 5vw);
  }
  .img-info{
    width: calc(55vh - 5vw);
    height: calc(55vh - 5vw);

    .info-container{
      .info-left{
        h2{
          margin-bottom: 5px;
        }
      }
    }
  }
}

//Mobile
@media (max-width: 768px) {
  .slide {
    width: calc(45vh - 5vw);
    height: calc(45vh - 5vw);
  }
  .img-info{
    width: calc(45vh - 5vw);
    height: calc(45vh - 5vw);

    .info-container{
      .info-left{
        h2{
          margin-bottom: 0px;
        }
      }
    }
  }
}

//Smaller Mobile
@media (max-width: 468px) {
  .slider-wrapper {
    // width: max-content;
    display: flex;
    flex-direction: column;

    .slide {
      width: 90vw;
      height: calc(40vh - 2vw);
      position: relative;
      img{
        transition: transform .3s;
      }
      .img-info{
        width: 100%;
        height: calc(40vh - 2vw);

        .info-container{
          .info-left{
            h2{
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}

.portofolio-main-wrapper{
    width: 100%;
    min-height: 100vh;
    padding: 0% 5%;
    padding-top: 100px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;

    // Heading
    .pmw-heading{
        width: 100%;
        padding-bottom: 40px;
        padding-top: 7%;
        display: flex;
        flex-direction: column;

        h1{
            font-weight: 200;
            font-size: calc(70px + 3vw);
        }
        h4{
        
            font-weight: 300;
            opacity: 0.6;
        }
        line{
            stroke: rgb(216, 172, 100, 0.8);
            stroke-width: 1;
        }
    }

    //Gallery
    .pmw-content{   
        width: 100%;
        position: relative;
        .content-grid{
            margin-bottom: 50px;

            .grid-list{
                grid-column-gap: 20px;
                grid-row-gap: 20px;
                grid-template-rows: auto;   
                // grid-template-columns: 1fr 1fr 1fr;
                grid-auto-columns: 1fr;
                display: grid;

                a{
                    width: 100%;
    
                    .image-container{
                        width: 100%;
                        height: 100%;
                        overflow: hidden;    
    
                        .image{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            background-size: cover;
                            background-position: center;

                            img{
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                transition: transform .3s;
                                filter: brightness(0.7);
                            }
                            .slide-title{
                                position: absolute;
                                bottom: 0;
                                color: white;
                                z-index: 10;
                                margin: 20px 20px;
                                transition: ease-in 0.3s;
                                transition-delay: 0.3s;
                                h2{
                                  opacity: 0.8;
                                  font-weight: 200;
                                }
                              }
                            .image-info{
                                position: absolute;
                                top: 0;
                                width: 100%;
                                height: 100%;
                                display: flex;
                                background-color: #000;
                                flex-direction: row;
                                justify-content: space-between;
                                align-items: end;
                                padding: 20px 30px;
                                opacity: 0;
                                transition: ease-in 0.4s;
    
                                .info-left{
                                    width: fit-content;
                                    h4 {
                                      font-weight: 200;
                                      color: var(--primary-color);
                                    }
                                  }
                                  .info-right{ 
                                    color: var(--primary-color);
                                  }
                            }

                        }
                    }
                    .image-container:hover{
                        img{
                            transform: scale(1.03) brightness(0.7);
                            filter: blur(10px);
                        }
                        .slide-title{
                            opacity: 0;
                            transition: none;
                            transition-delay: 0s;
                        }
                        .image-info{
                            opacity: 0.6;

                            .info-right{
                                .info-arrow{
                                    animation: arrow_up 1s forwards;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    //Back top button
    .back-top-button{
        margin: 70px 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        .bottom{
            margin-top: -3px;
        }

        p{
            font-size: 17px;
        }
        
    }
    .back-top-button:hover{
        .top{
            animation: arrow 2s ease;
            // animation-delay: -.3s;
        }
        .bottom{
            animation: arrow 2s ease;
            animation-delay: -.3s;
        }
    }
}

@keyframes arrow{
    0%{
        opacity: 0;
        // transform: translate(0, 5px);
    }
    100%{
        opacity: 1;
    }
}

@keyframes arrow_up{
    0%{
        transform: translate(0, 0);
    }
    100%{
        transform: translate(5px, -5px);
    }
}

//Desktop
@media (min-width: 1025px) {
    .pmw-heading{
        h4{
            width: 50%;
            font-size: calc(1px + 1vw);
        }
    }
    .grid-list{
        grid-template-columns: 1fr 1fr 1fr;
        a{
            height: calc(50px + 25vw);
        }
    }
}

//Small Desktop, Tab
@media (max-width: 1024px) {
    .pmw-heading{
        h4{
            width: 70%;
            font-size: calc(5px + 1vw);
        }
    }
    .grid-list{
        grid-template-columns: 1fr 1fr;
        a{
            height: calc(50px + 35vw);
        }
    }
}

//Mobile
@media (max-width: 576px) {
    .pmw-heading{
        h4{
            width: 100%;
            font-size: calc(7px + 1vw);
        }
    }
    .grid-list{
        grid-template-columns: 1fr;
        a{
            height: calc(50px + 55vw);
        }
    }
}


.banner-wrapper{
    width: 100%;
    height: 100%;
    background-image: url("../../../../public/assets/banner_4.png");
    background-size: cover;
    padding: 3% 3%;
    color: #FBFBFB;
    display: flex;
    align-items: end;
    
    .banner-description{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: end;
        cursor: default;

        .banner-icon{
            text-align: end;
            margin-bottom: -2%;
            .banner-arrow{
                width: calc(10px + 4vw);
                height: calc(10px + 4vw);
            }
        }

        .banner-text{
            h1{
                line-height: calc(10px + 5vw);
                font-weight: 100;
            }

            #text-mark{
                color: var(--primary-color-gold);
                font-weight: 100;
            }
        }
    }

    .banner-description:hover{
        .banner-icon{
            animation: iconUp 0.8s forwards;
        }
    }

    @keyframes iconUp {
        0%{
            transform: translate(0, 0);
        }
        100%{
            transform: translate(8px, -8px);
        }
    }
}

//Desktop, Tablet
@media (min-width: 1024px) {
    .banner-icon{
        width: calc(150px + 55vw);
    }
    .banner-text{
        h1{
            letter-spacing: -2px;
            font-size: calc(25px + 4vw);
        }
    }
}

@media (max-width: 1023px) {
    .banner-icon{
        width: calc(150px + 55vw);
    }
    .banner-text{
        h1{
            letter-spacing: -2px;
            font-size: calc(25px + 4vw);
        }
    }
}

//Mobile
@media (max-width: 640px) {
    .banner-icon{
        width: calc(100px + 50vw);
    }
    .banner-text{
        h1{
            letter-spacing: -1px;
            font-size: calc(15px + 3vw);
        }
    }
}
.navbar {
  position: fixed;
  width: 100vw;
  background-color: var(--primary-color);
  z-index: 20;

  .nav-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.3% 5% 7px;

    img{
      width: calc(100px + 15vw);
      height: auto;
    }

    .menu {
      display: flex;
      padding-bottom: 10px;
      gap: 40px;

      a {
        font-weight: 300;
        font-size: calc(1rem + 0.7vw);
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
      }

      a:hover {
        font-weight: 400;
      }
    }
  }

  // hamburger menu
  .button{
    position: fixed;
    top: 0;
    right: 0;
    width: calc(15px + 5vw);
    margin-right: 5%;
    margin-top: 15px;
    cursor: pointer;
    z-index: 10;

    .burger{
      width: 100%;
      .line{
        width: 60%;
        background-color: var(--primary-color-gold);
        display: block;
        transition: all 0.5s ease-in-out;
      }
    }

    .burgerActive{
      z-index: 10;
      .line:nth-child(1){
        background-color: white;
      }
      .line:nth-child(2){
        background-color: white;
      }
    }
  }
}

.buttonActive{
  pointer-events:all;
}

//Desktop
@media (min-width: 1025px ) {
  .button{
    pointer-events: none;
    opacity: 0;
  }
}
@media (max-width: 1024px ) {
  .menu{
    visibility: hidden;
  }
  .burger{
    .line{
      height: 3px;
      margin: 10px 0px;
    }
  }
  .burgerActive{
    z-index: 10;
    .line:nth-child(1){
      transform: translateY(15px) rotate(45deg);
    }
    .line:nth-child(2){
      transform: translateY(2px) rotate(-45deg);
    }
  }
}

//Tab
@media (max-width: 728px ) {
  .burger{
    .line{
      height: 2.5px;
      margin: 7px 0px;
    }
  }
  .burgerActive{
    z-index: 10;
    .line:nth-child(1){
      transform: translateY(13px) rotate(45deg);
    }
    .line:nth-child(2){
      transform: translateY(4px) rotate(-45deg);
    }
  }
}

//Mobile
@media (max-width: 576px) {
  .burger{
    .line{
      height: 2px;
      margin: 5px 0px;
    }
  }
  .burgerActive{
    z-index: 10;
    .line:nth-child(1){
      transform: translateY(20x) rotate(45deg);
    }
    .line:nth-child(2){
      transform: translateY(8px) rotate(-45deg);
    }
  }
}


.container {
  padding: 0% 5%;
  padding-top: 20rem;
}

.banner{
  height: 50vw;
  margin-bottom: 15vw;
}


//Desktop
@media (min-width: 1280px) {
  section {
    margin-bottom: 15vw;
  }
  .about{
    margin-top: 15vw;
  }
}

//Tablet
@media (min-width: 768px ) and (max-width: 1280px) {
  section {
    margin-bottom: 20vw;
  }
}

//Mobile
@media (max-width: 768px ) {
  section {
    margin-bottom: 20vw;
  }
  .about, .banner, .portofolio, .service{
    margin-top: 20vw;
  }
}

//Smaller Mobile
@media (max-width: 468px) {
  section {
    margin-bottom: 25vw;
  }
  .container{
    padding-top: calc(350px - 20%);
  }
}





.client-container{
    width: 100%;
    height: fit-content;
    margin-top: 5vw;
    margin-bottom: 100px;

    .client-top-caption{
        width: 100%;
        display: flex;
        justify-content: end;
        margin-bottom: 50px;

        p{
            font-weight: 200;
        }
    }

    .client-gallery{
        width: 100%;
        display: grid;

        .client-gallery-left{
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 3vw;

            .cgl-image-wrap{
                height: 100%;
                display: flex;
                flex-direction: row;
                gap: 15px;

                .cgl-image-small{
                    width: 40%;
                    margin-top: 250px;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .cgl-image-med{
                    width: 55%;
                    height: 80%;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
            h3{
                font-weight: 200;
                line-height: 45px;
            }
        }
        .client-gallery-right{
            width: 100%;
            display: flex;
            flex-direction: row;
            gap: 15px;

            .cgr-image-med{
                width: 55%;
                margin-top: 100px;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .cgr-image-small{
                width: 45%;
                height: 50%;
                margin-top: 40px;

                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}

//Desktop
@media  (max-width: 1920px) {
    .client-top-caption{
        p{
            width: 50%;
            font-size: 20px;
        }
    }

    .client-gallery{
        gap: 5px;
        grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
        .client-gallery-left{
            h3{
                font-size: 40px;
            }
        }
    }

}

//Tab, Mobile
@media (max-width: 768px) {
    .client-gallery{
        gap: 15px;
        .client-top-caption{
            p{
                width: 75%;
                font-size: 20px;
            }
        }
    }
}

@media (max-width: 564px) {
    .client-container{
        margin-bottom: 50px;
        .client-top-caption{
            width: 100%;
            margin-bottom: 20px;
            p{
                width: 100%;
            }
        }
        .client-gallery{
            grid-template-columns:1fr;
            gap: 50px;
            // grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));

            .client-gallery-left{
                order: 1;
                h3{
                    font-size: 35px;
                    line-height: 7vw;
                    margin-top: 30px;
                }
            }
        }
    }
}
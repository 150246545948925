.portofolio-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  // Heading
  .title-heading {
    display: flex;
    justify-content: space-between;

    h1 {
      font-weight: 200;
      font-size: calc(30px + 6vw);
      padding-bottom: 2vw;
    }

    .th-button{
      padding-bottom: 1%;
      a{
        h4{
          font-weight: 300;
        }
      }
    }
  }

  // Slider
  .pw-slider {
    position: relative;
    // height: max-content;
    width: 100%;
  }
}

//Desktop
@media (min-width: 1280px ) {
  .title-heading {
    flex-direction: row;
    align-items: end;
  }
}
//Tablet
@media (min-width: 768px ) and (max-width: 1280px) {
  .title-heading {
    align-items: end;
  }
}
//Mobile
@media (max-width: 768px ) {
  .title-heading {
    flex-direction: column;
    align-items: start;
  }
}

//Smaller Mobile
@media (max-width: 468px) {
}

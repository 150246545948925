.about-page-wrapper {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: end;

  .content-wrapper{
    width: 100%;

    .top-caption{
      width: 65vw;
      position: relative;

      h2{
        display: flex;
        margin: 0px;
        flex-wrap: wrap; 

        &:nth-of-type(1){
          display: flex;
          flex-direction: row;
          gap: 8px;
          font-size: calc(2.5rem + 2vw);   
          line-height: 1.2;
          
          span{
            margin-right: 3px;
          }
          .mask{
            position: relative;
            overflow: hidden;
            display: inline-flex;
          }
        }
      }
    }
  
    .line{
      width: 100%;
      // background-color: red;
      svg{
        width: 100%;
        height: 5rem;
        margin-top: 1vw;
  
        line{
          stroke: var(--primary-color-gold);
          stroke-width: 0.8;
        }
      }
    }
  
    .bottom-caption{
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: end;

      .bottom-button{
        width: 40%;
      }

      .caption-small{
        width: 60%;
        display: flex;
        justify-content: end;
        p{
          width: 70%;
          text-align: end;
        }
      }
    
    }
  }
}

//Desktop
@media (min-width: 1280px ) {

}
//Tablet
@media (min-width: 768px ) and (max-width: 1280px) {

}
//Mobile
@media (max-width: 768px ) {

}
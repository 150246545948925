.expanded-wrapper{
    height: 100vh;
    width: 100vw;
    background-color: #f7d399;
    z-index: 2;
    position: fixed;
    // top: 50px;
    // right: 50px;
    top: 0;
    right: 0;


    .expanded-menu{
        // height: 100%;
        // padding: 100px;
        padding-right: 50px;
        padding-top: 200px;
 
        .menu-list{
            display: flex;
            flex-direction: column;
            align-items: end;
            user-select: none;

            a{
                font-size: 40px;
                color: white;
                transition: color 0.3s ease;
            }   
            a:hover{
                color: var(--primary-color-gold);
            }
        }
    }
}
.home-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .mark {
    font-family: "Baskervville", serif;
    font-style: italic;
    color: var(--primary-color-gold-rgb);
  }

  .hw-heading {
    width: 100%;
    margin-bottom: 4%;
    h1 {
      font-weight: 200;
    }
  }
  
  .hw-banner {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;

    .hw-b-button {
      width: fit-content;
      display: flex;
  
      h4{
        font-weight: 300;
      }
    }
    .hw-b-image {
      video{
        opacity: 1;
        filter: brightness(30%);
      }
    }
  }
}

//Desktop
@media (min-width: 1280px ) {
  .hw-heading {
    h1 {
      line-height: 120px;
      font-size: calc(90px + 2vw);
    }
  }
  .hw-b-image {
    width: 100%;
    video{
      width: 100%;
    }
  }

}
//Tab
@media (max-width: 1279px) {
  .hw-heading {
    margin-bottom: 4%;
    h1 {
      letter-spacing: 0vw;
      line-height: 80px;
      font-size: calc(60px + 2vw);
    }
  }
  .hw-b-image {
    width: 100%;
    video{
      width: 100%;
    }
  }
}
//Mobile
@media (max-width: 768px) {
  .hw-heading {
    h1 {
      letter-spacing: -2px;
      line-height: 70px;
      // font-size: calc(3px + 12vw);
    }
  }
}
//Smaller Mobile
@media (max-width: 468px) {
  .hw-heading {
    h1 {
      line-height: 15vw;
      font-size: calc(10px + 14vw);
    }
  }
}



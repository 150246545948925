.button-wrapper {
  width: fit-content;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  .b-icon {
    display: flex;
    flex-direction: row;
    justify-content: end;

    svg{
      width: calc(5px + 1vw);
      height: auto;
    }
  }

  .b-button {
    margin-top: -5px;
    padding-right: 10px;
    
    button {
      border: none;
      background-color: transparent;
      cursor: pointer;

      h4 {
        position: relative;
        font-weight: 300;
        font-size: calc(10px + 1vw);
      }

      h4::after{
        content: "";
        position: absolute;
        height: 1px;
        left: 0;
        bottom: 3px;
        width: 0;
        background: rgb(216, 172, 100);
        opacity: 0.7;
        transition: width 0.5s;
        transition-delay: 0.2s;
      }

      h4:hover::after{
        width: 100%;
        
      }
    }
  }
}

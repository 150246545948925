.service-wrapper {
  width: 100%;
  height: fit-content;
  .mark {
    color: var(--primary-color-gold-rgb);
  }

  .sw-content {
    // Accordion
    .sw-right {
      position: relative;
      display: flex;
      justify-content: end;
      // align-items: end;
      .accordion-content {
        width: 100%;
        height: fit-content;

        .nav-list{
          position: relative;
        }
      }
    }
  }
}

// Desktop, Tablet
@media (min-width: 640px) {
  .sw-content {
    width: 100%;
    // height: 100%;
    display: flex;
    gap: 15vw;

    .sw-left {
      width: 25%;
      .description {
        h2 {
          font-weight: 300;
          font-size: 2.3vw;
        }
      }
    }

    .sw-right {
      width: 75%;
      padding-top: 8vw;
    }
  }
}

// Smaller mobile
@media (max-width: 640px) {
  .sw-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8vw;

    .sw-left {
      width: 85%;
      .description {
        h2 {
          font-weight: 300;
          font-size: 5vw;
        }
      }
    }

    .sw-right {
      display: flex;
      flex-direction: row;
      gap: 10vw;
      justify-content: space-between;

      .right-content {
        width: 60%;
      }
    }
  }
}

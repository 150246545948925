.footer {
  height: 95vh;
  background-attachment: fixed;
  background-size: cover;
  background-image: url("../../../public/assets/footer_img.png");
  padding-top: 2%;
  
  h1,
  h2,
  h4,
  label,
  p {
    font-weight: 300;
    color: var(--primary-color);
  }

  .mark {
    font-weight: 400;
    color: var(--primary-color-gold);
  }

  .foo-wrapper {
    width: 100%;
    height: 100%;
    padding: 5% 5% 1%;
    display: flex;
    flex-direction: column;

    .contact {
      height: 100%;
      display: flex;
      // flex-direction: row;

      // Description and contact (left)
      .contact-desc {
        height: 100%;
        padding-top: 5%;
        display: flex;
        cursor: default;
        // background-color: lightblue;

        .cd-address {
          display: flex;
          gap: 50px;
        }
      }
    }

    // Content (Right)
    .contact-right {
      height: 100%;
      display: flex;
      padding-top: 5%;

      .cl-content{
        display: flex;
        padding-bottom: 3%;
        
        .content-menu{
          display: flex;
          flex-direction: column;
          gap: 5px;

          a{
            color: var(--primary-color);
            transition: color 0.3s ease;
            font-weight: 200;
          }
          a:hover{
            color: var(--primary-color-gold);
          }
        }

        .cd-social {
          display: flex;
          gap: 15px;
          
          a{
            svg{
              color: var(--primary-color);
              transition: ease .6s;
              width: calc(20px + 1vw);
              height: calc(20px + 1vw);
            }
          }

          a:hover{
            svg{
              color: var(--primary-color-gold);
              opacity: 0.5;
            }
          }
        }
      }
    }

    // Bottom copyright
    .copyright {
      opacity: 0.7;
      
      .line {
        margin-bottom: 1%;
        border-bottom: 1px solid var(--primary-color-gold);
      }
      .copy-text{
        display: flex;
        flex-direction: row;
        gap: 0.6vw;
        span{
          color: white;
          display: flex;
          svg{
            width: calc(5px + 1vw);
            height: calc(5px + 1vw);
          }
        }
      }
    }
  }
}

//Desktop
@media (min-width: 1280px) {

}

//Tablet
@media (min-width: 1024px ){

}

//Mobile
@media (min-width: 641px ) {
  .contact {
    width: fit-content;
    flex-direction: row;
    
    .contact-desc {
      width: 70%;
      flex-direction: row;
      gap: 2vw;

      .cd-text {
        width: 40%;
        p {
          width: 70%;
          font-size: calc(6px + 1.4vw);
          font-weight: 200;
        }
      }
      .cd-address {
        width: 60%;
        flex-direction: column;
        
        h2 {
          font-size: calc(10px + 1vw);
          font-weight: 200;
          color: var(--primary-color-gold)
        }
        p{
          font-size: calc(5px + 1vw);
          font-weight: 200;
        }
      }
    }
    .contact-right {
      width: 30%;
      justify-content: end;
      .cl-content{
        flex-direction: column;
        justify-content: space-between;
        .content-menu{
          a{
            font-size: calc(10px + 1vw);
          }
        }
      }
    }
  }
}

//Smaller Mobile
@media (max-width: 640px) {
  .contact {
    width: 100%;
    flex-direction: column;
    .contact-desc {
      width: 100%;
      flex-direction: column;
      gap: 10vw;

      .cd-text {
        p {
          font-size: calc(3px + 4vw);
          font-weight: 200;
        }
      }
      .cd-address {
        flex-direction: row;
        
        h2 {
          font-size: calc(6vw);
          font-weight: 200;
          color: var(--primary-color-gold)
        }
        p{
          font-size: calc(5px + 3vw);
          font-weight: 200;
        }
      }
    }
    .contact-right {
      align-items: end;
      .cl-content{
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        .content-menu{
          a{
            font-size: calc(10px + 2vw);
          }
        }
        .cd-social {
          align-items: end;
        }
      }
    }
  }
}

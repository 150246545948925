.accordion-wrapper {
  position: relative;
  width: 100%;
  border-bottom: 1px solid rgb(216, 172, 100, 0.5);
  cursor: default;
  
  .aw-image{
    position: absolute;
    width: 200px;
    height: 100px;
    top: 0;
    right: 0;
    z-index: 20;

    img{
      width: 100%;
      height: 100%;
      object-fit:cover;
    }
  }

  .aw-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-top: 1vw;
    padding-bottom: 0.5vw;
    position: relative;
    z-index: 10;

    .aw-t-title{
      display: flex;
      flex-direction: row;
      gap: 15px;
      transition: all 0.2s linear;

      h1{
        font-weight: 200;
        margin-bottom: -1vw;
        transition: all 0.2s linear;
      }
      h2{
        font-weight: 200;
        transition: all 0.2s linear;
        transition-delay: 0.1s;
        opacity: 0.6;
      }
    }
    .aw-t-title:hover h1{
      transform: translateY(-5px);
    }
    .aw-t-title:hover h2{
      transform: translateY(-10px);
    }
  }
}

//Desktop
@media (min-width: 1280px) {
  .aw-t-title{
    h1{
      letter-spacing: -2px;
      font-size: calc(5px + 5vw);  
    }
  }
}

//Tablet
@media (max-width: 1280px) {
  .aw-t-title{
    h1{
      letter-spacing: -2px;
      font-size: calc(5px + 5vw);
    }
  }
}

//Mobile
@media (min-width: 768px) and (max-width: 1024px) {
  .aw-t-title{
    h1{
      letter-spacing: -2px;
      font-size: calc(3px + 5vw);
    }
  }
}

//Smaller Mobile
@media (max-width: 767px) {
  .aw-t-title{
    h1{
      letter-spacing: -1px;
      font-size: calc(3px + 6.7vw);
      margin-top: 5px;
    }
  }
}

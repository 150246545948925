.about-container{
    width: 100%;
    height: fit-content;
    margin: 15vw 0;
    display: flex;
    flex-direction: row;
    gap: 5vw;
    // background-color: red;

    .about-banner-container{
        width: 100%;

        img{
            width: 100%;
            height: 100%;
        }
    }

    .about-desc-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: center;

        h2{
            font-size: 48px;
            font-weight: 300;
        }
        p{
            font-size: 1.4vw;
            font-weight: 200;
        }
    }
}

//Tab
@media (max-width: 768px) {
    .about-container{
    // flex-direction: column-reverse;
        .about-desc-container{
    
            h2{
                font-size: 45px;
            }
            p{
                font-size: 2.3vw;
            }
        }
    }
}


//Mobile
@media (max-width: 576px) {
    .about-container{

        .about-banner-container{
           
    
            img{
                
            }
        }
        .about-desc-container{
            gap: 2vw;
            h2{
                font-size: 25px;
            }
            p{
                font-size: 2.3vw;
            }
        }
    }
}